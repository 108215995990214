import {BooleanInput, TextInput} from "react-admin";
import React from "react";

export const ActionPetitionEnableUser = () => {

    return (
        <>
            <TextInput name="email" source="email" disabled></TextInput>
            <BooleanInput name="enabled" source="enabled" />
        </>
    );

}

export function ActionConfirmEnableUser(data) {

    const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

    const updatedRecord = { enabled: data.enabled };

    return fetch(`${urlAPI}/users/${data.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord) })
        .then(response => response.json());

}