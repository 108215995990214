import {ArrayInput, BooleanInput, SimpleFormIterator, TextField} from "react-admin";
import React from "react";

export const ActionPetitionEnableUserServices = () => {

    return (
        <>
            Activar / Desactivar Servicios de usuarios
            <ArrayInput source="services" label=" " sx={{
                display: 'flex'
            }}>
                <SimpleFormIterator disableReordering="true" disableRemove disableAdd disabled sx={{
                    width: 300,
                    top: 10,
                    color: 'info.main',
                  //  display: 'flex'
                }} getItemLabel={index => ``}>
                    <TextField source="title"></TextField>
                    <BooleanInput source="active" key="name" label=" " />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );

}

export function ActionConfirmEnableUserServices(data) {

    const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

    const updatedRecord = { enabled: data.services };

    return fetch(`${urlAPI}/users/services/${data.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord) })
        .then(response => response.json());

}