// in src/estados.js
import React from "react";
import {Stack, useMediaQuery} from '@mui/material';
import {
    ChipField,
    Datagrid,
    List,
    SimpleList,
    SingleFieldList,
    TextField,
    TextInput,
    ShowButton,
    SimpleForm,
    Edit,
    EditButton,
    ArrayField,
    SelectArrayInput, useRedirect, DeleteWithConfirmButton, useRecordContext
} from "react-admin";
import {StringToLabelObject} from "./App";
import Button from "@mui/material/Button";

const roles = [
    { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
    { id: 'ROLE_LEAD_MANAGER', name: 'ROLE_LEAD_MANAGER' },
    { id: 'ROLE_GERENTE', name: 'ROLE_GERENTE' },
    { id: 'ROLE_JV_PLUS', name: 'ROLE_JV_PLUS' },
    { id: 'ROLE_JEFE_VENTAS', name: 'ROLE_JEFE_VENTAS' },
    { id: 'ROLE_CONTACT_CENTER', name: 'ROLE_CONTACT_CENTER' },
    { id: 'ROLE_CONTROL_CALIDAD', name: 'ROLE_CONTROL_CALIDAD' },
    { id: 'ROLE_COMERCIAL', name: 'ROLE_COMERCIAL'}
];

export const CategoriasLicenciasList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List>
            { isSmall ? (
                <SimpleList 
                primaryText={record => record.nombre}
                secondaryText={record => record.id}
                />
            ) : (
                <Datagrid>
                    <TextField source="id"/>
                    <TextField label="Nombres" source="name"/>
                    <TextField label="Estado" source="state.name"/>
                    <ArrayField source="roles">
                        <SingleFieldList>
                            <StringToLabelObject>
                                <ChipField source="label" />
                            </StringToLabelObject>
                        </SingleFieldList>
                    </ArrayField>
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}


export const CategoriasLicenciasEdit = props => {

    const redirect = useRedirect();

    const handleConfirm = async (data) => {

        const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

        const params = { id: data.id, roles: data.roles };

        let response = await fetch(`${urlAPI}/categoriasLicencias/${data.id}`, { method: 'PUT', body: JSON.stringify(params) })
            .then(response => response.json());

        if (response) {
            redirect('/clientes/'+response.id+'/show');
        }

    }

    return (
        <>
        <Edit title="Licencias">
            <SimpleForm onSubmit={handleConfirm}>
                <TextInput disabled source="id" />
                <TextInput disabled source="idLicense" />
                <TextInput label="Nombres" source="name"/>
                <TextInput label="Estado" source="state.name"/>

                <SelectArrayInput label="Roles" source="roles" choices={roles} />

            </SimpleForm>
        </Edit>
        </>
    )
};
