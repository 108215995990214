
import {
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    EditButton,
    useRecordContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ReferenceInput,
    SelectInput,
    ListBase,
    FilterForm,
    Pagination,
    Edit,
    SimpleForm,
    useNotify,
    TextInput,
} from 'react-admin';
import {useMediaQuery, Stack} from '@mui/material';

import {BillingEditDialog} from "./Utils/Dialogs/BillingEditDialog";
import React from "react";
import Button from "@mui/material/Button";
import ContentCopy from '@mui/icons-material/ContentCopy';

import {CopyToClipboard} from 'react-copy-to-clipboard';

const BillingsTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>Facturación: {record.cif}</span>;
}

const  billingsFilters = [
    <TextInput label="Buscar..." source="q" alwaysOn />,
    <ReferenceInput label='Facturación' source='id' reference='billings' allowEmpty perPage={-1} sort={{field:'cif', order:'ASC'}}>
        <SelectInput optionText="cif" />
    </ReferenceInput>
];

const BillingsListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={billingsFilters} />
        <div>
            <FilterButton filters={billingsFilters} />
            <CreateButton />
        </div>
    </Stack>
)

const BillingsShowActions = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

export const CopyData = (action, props) => {

    action = Object.values(action);
    const record = useRecordContext();
    const notify = useNotify();
    let data = "";

    const handleClick = async (data) => {
        notify('Se ha copiado el ' + action, { autoHideDuration: 5000, type: "success" });
    }

    if (action == 'id') {
        data = record.id;
    }

    if (action == 'company_name') {
        data = record.company_name;
    }

    if (action == 'company_cif') {
        data = record.company_cif;
    }

    if (action == 'company_email') {
        data = record.company_email;
    }

    if (action == 'company_phone') {
        data = record.company_phone;
    }

    if (action == 'contact_phone') {
        data = record.contact_phone;
    }

    if (action == 'contact_email') {
        data = record.contact_email;
    }

    if (!data) {
        return "";
    } else {
        return <CopyToClipboard text={data} >
            <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
        </CopyToClipboard>
    }

}

export const BillingsShow = () => (
    <Show title={<BillingsTitle />} actions={<BillingsShowActions/>}>
        <SimpleShowLayout>
                    <TextField source="id" />
        </SimpleShowLayout>
    </Show>
);

export const BillingsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
    <ListBase>
        <BillingsListToolbar />
        {isSmall ? (
            <SimpleList
            primaryText={record => record.company_cif}
            secondaryText={record => record.id}
            tertiaryText={record => record.activo}
            />
            ) : (
            <Datagrid>

                <div label="ID">
                    <TextField source="id"/>
                    <CopyData action="id"></CopyData>
                </div>

                <div label="Cliente">
                    <TextField source="cliente"/>
                    <CopyData action="cliente"></CopyData>
                </div>

                <div label="Concesionario">
                    <TextField source="concesionario"/>
                    <CopyData action="concesionario"></CopyData>
                </div>

                <div label="Razón Social">
                    <TextField source="company_name"/>
                    <CopyData action="company_name"></CopyData>
                </div>

                <div label="CIF">
                    <TextField source="company_cif"/>
                    <CopyData action="company_cif"></CopyData>
                </div>

                <div label="Dirección">
                    <TextField source="fiscal_address"/>
                    <CopyData action="fiscal_address"></CopyData>
                </div>

                <div label="Contacto">
                    <TextField source="contact_name"/>
                    <CopyData action="contact_name"></CopyData>
                </div>

                <div label="Email">
                    <TextField source="contact_email"/>
                    <CopyData action="contact_email"></CopyData>
                </div>

                <div label="Teléfono">
                    <TextField source="contact_phone"/>
                    <CopyData action="contact_phone"></CopyData>
                </div>

                <div label="Activo">
                    <BillingEditDialog action="enableBilling"/>
                </div>

                <div label="Creado">
                    <TextField source="created_at"/>
                    <CopyData action="created_at"></CopyData>
                </div>

                <div label="Actualizado">
                    <TextField source="updated_at"/>
                    <CopyData action="updated_at"></CopyData>
                </div>

                <EditButton />

            </Datagrid>
        )}
        <Pagination/>
    </ListBase>
    );

};

export const BillingsEdit = props => (
    <Edit {...props}>
        <SimpleForm>

            <TextInput label="ID" source="id" disabled />
            <TextInput label="Razón Social" source="company_name" />
            <TextInput label="CIF" source="company_cif" />
            <TextInput label="Dirección" source="company_address" />
            <TextInput label="Contacto" source="contact_name" />
            <TextInput label="Email" source="contact_email" />
            <TextInput label="Teléfono" source="contact_phone" />

        </SimpleForm>
    </Edit>
);
