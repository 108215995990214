import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    Filter,
 } from 'react-admin';
 import { useMediaQuery } from '@mui/material';
 
 
 const FieldExcluseFilter = (props) => (
     <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const FieldExcluseList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List title="Lista de Campos excluidos de More Info" filters={<FieldExcluseFilter />} >
        {isSmall ? (
            <SimpleList
            primaryText={record => record.name}
            />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" label="Dato" />
                </Datagrid>
                    )}
            
    </List>
);
            };

export const FieldExcluseCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput source="name" reference="logkeys" label="Dato" perPage={-1}>
                <SelectInput optionText="key" optionValue="id" optionId="id" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
