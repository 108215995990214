// in src/clientes.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    TextInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField,
    ArrayField,
    useRedirect,
    useRecordContext,
    useRefresh
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {StringToLabelObject} from "./App";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import {LoadData} from "./Utils/Services/Edit_services";

const ClientesFilter = [
    <TextInput label="Buscar..." source="q" alwaysOn/>,
];

const urlAPI = `${process.env.REACT_APP_API_URL}`;

function replicarTiposCliente(id)
{

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(`${urlAPI}replicar/tipos/leads/cliente/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

}

const DashboardButton = (props) => {

    const record = useRecordContext();
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('/categoriasLicencias/'+record.id);
    };

    let nameButton = (record.new) ? 'Crear' : 'Editar';

    return <Button className="classes.btn_create" onClick={handleClick} {...props} >{nameButton}</Button>;
};

const DeletedButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const handleClick = async () => {

        let response = await fetch(`${urlAPI}lt/categoriasLicencias/${record.id}`, {method: 'DELETE'})
            .then(response => response.json());

        if (response) {
            refresh();
        }

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Eliminar</Button>;
};


const ServicesButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const handleClick = async (data) => {

       /* let response = await fetch(`${urlAPI}lt/categoriasLicencias/${record.id}`, {method: 'DELETE'})
            .then(response => response.json());

        if (response) {
            refresh();
        }*/

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Actualizar servicio</Button>;
};


export const ClientesShow = () => (
    <Show>
        <SimpleShowLayout>

            <h1>Datos del cliente</h1>
            <TextField source="nombre" />
            <TextField source="api_token" />
            <BooleanField source='activo' />
            <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios' link='show'>
            <SingleFieldList linkType="show">
                <ChipField source='nombre' />
            </SingleFieldList>
            </ReferenceArrayField>
            <FunctionField render={(record) => (
                <Button label='Replicar tipos de leads' onClick={() => replicarTiposCliente(record.id)}>Replicar tipos de leads</Button>
            )} />

            <LoadData typeData="client"></LoadData>

            <h1>Listado de estados</h1>
            <ArrayField label="Estados - Licencias" source="states">
                <Datagrid>

                    <TextField source="id" />

                    <TextField source="state.name" />

                    <ArrayField source="roles">
                        <SingleFieldList>
                            <StringToLabelObject>
                                <ChipField source="label" />
                            </StringToLabelObject>
                        </SingleFieldList>
                    </ArrayField>

                    <DashboardButton ></DashboardButton>

                    <DeletedButton></DeletedButton>

                </Datagrid>
            </ArrayField>


        </SimpleShowLayout>
    </Show>
);

export const ClientesList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={ClientesFilter}>
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '1rem'}}>
                <ClientReportButton/>
            </div>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>
                    <TextField source="id"/>

                    <FunctionField
                        source="nombre"
                        render={record => (
                            <span style={{ color: record.activo ? 'inherit' : 'red' }}>
            {record.nombre}
        </span>
                        )}
                    />

                    <FunctionField
                        source="activo"
                        render={record => (
                            <span style={{ color: record.activo ? 'inherit' : 'red' }}>
                                {record.activo ? <DoneIcon color="success" /> : <ClearIcon color="error" />}
                            </span>
                        )}
                    />

                    <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios'>
                        <SingleFieldList linkType="show">
                            <FunctionField render={(record) => {
                                if (!record) return null;
                                const marcas = record.marcas ? ` (${record.marcas})` : '';
                                const label = `${record.nombre}${marcas}`;
                                return (
                                    <Chip
                                        label={label}
                                        variant="outlined"
                                        color={record.activo ? 'success' : 'error'}
                                    />
                                );
                            }} />
                        </SingleFieldList>
                    </ReferenceArrayField>

                    <ShowButton/>

                    <FunctionField render={(record) => (
                        <Button label='' onClick={() => replicarTiposCliente(record.id)}>Replicar tipos</Button>
                    )}/>

                </Datagrid>
            )}
        </List>
    );
};

const downloadClientReport = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}lt/clients/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
            }
        });

        if (!response.ok) {
            throw new Error('Error al descargar el informe');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;
        const fileDownloadName = `USERS - INFORME USUARIOS POR CLIENTE ${formattedDate}.xlsx`;
        link.setAttribute('download', fileDownloadName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error:', error);
    }
};

const ClientReportButton = () => (
    <Button
        startIcon={<DownloadIcon />}
        onClick={downloadClientReport}
    >
        Informe de clientes
    </Button>
);
