// in src/categoriasTipos.js
import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Create,  TextInput, ReferenceInput, BooleanInput, SelectInput, SimpleForm } from 'react-admin';

export const CategoriasTiposList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" label="Id"/>
            <TextField source="nombre" label="Nombre" />
            <ReferenceField source="categoria" reference="categoriasTipos" label="Categoria Padre">
                        <TextField source="nombre" />
                    </ReferenceField>
            {/*<TextField source="username" />*/}
            {/*<EmailField source="email" />*/}
            {/*<TextField source="address.street" />*/}
            {/*<TextField source="phone" />*/}
            {/*<MyUrlField source="website" />*/}
            {/*<TextField source="company.name" />*/}
        </Datagrid>
    </List>
);

export const CategoriasTiposCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nombre" source="nombre" />
            <ReferenceInput source="id_parent" reference="categoriasTipos" label="Categoría Padre" perPage={-1} filter={{ is_parent: 1 }}>
                           <SelectInput optionText="nombre" />
            </ReferenceInput>
            <BooleanInput label="Tiene ID externo" source="has_id" />
            <TextInput label="Nombre ID externo" source="name_field_id" />
        </SimpleForm>
    </Create>
);
