import {
    BooleanField, ImageField,
    SaveButton,
    SimpleForm,
    useNotify, useRecordContext,
    useRefresh,
} from "react-admin";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toolbar from "@mui/material/Toolbar";
import DialogActions from "@mui/material/DialogActions";
import {ActionPetitionEnableUser, ActionConfirmEnableUser} from "./EnableDisableUser";
import {ActionPetitionEnableZapier, ActionConfirmEnableZapier} from "./EnableDisableZapier";

import AccountCircle from '@mui/icons-material/AccountCircle';
import Bolt from '@mui/icons-material/Bolt';
import {ActionConfirmEnableUserServices, ActionPetitionEnableUserServices} from "./EnableDisableUserServices";

export const UserEditDialog = (action, props) => {

    action = Object.values(action);

    const [open, setOpen] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async (data) => {

        let actionResponse;

        // Se pueden añadir más acciones para peticiones fecth
        if (action == 'enableUser') {
            actionResponse = await ActionConfirmEnableUser(data);
        }

        if (action == 'enableZapier') {
            actionResponse = await ActionConfirmEnableZapier(data);
        }

        if (action == 'enableUserServices') {
            actionResponse = await ActionConfirmEnableUserServices(data);
        }

        let statusAction = (actionResponse.error === false) ? 'success' : 'error';
        let messageAction = actionResponse.message;

        refresh();
        notify(`${messageAction}`, { type: `${statusAction}` });

        setOpen(false);

    };

    // Se pueden añadir más input form para petición customizada
    let buttonText = '';
    let formPetition = '';
    if (action == 'enableUser') {
        buttonText = <BooleanField source="enabled" />;
        formPetition = <ActionPetitionEnableUser />;
    }

    if (action == 'enableZapier') {

        let color= "success";
        if (record.zapier) {
            color= "error";
        }

        buttonText = <Button color={color}><Bolt></Bolt>&nbsp;Zapier</Button>
        formPetition = <ActionPetitionEnableZapier />;
    }

    if (action == 'enableUserServices') {

        let color= "success";
        if (record.userServices) {
            color= "error";
        }

        buttonText = <Button color={color}><AccountCircle></AccountCircle>&nbsp;Servicios</Button>
        formPetition = <ActionPetitionEnableUserServices />;
    }

    return (
        <>

            <Button label="Deshabilitar usuario" onClick={handleClick} >{buttonText}</Button>

            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Seguro desea realizar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <SimpleForm
                            form="post-quick-create"
                            resource="put"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={handleConfirm}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                            component="p"
                        >

                            {formPetition}

                            <Toolbar>
                                <Button onClick={handleDialogClose}>Cancelar</Button>
                                <SaveButton label="Actualizar" />
                            </Toolbar>

                        </SimpleForm>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>


                </DialogActions>
            </Dialog>
        </>
    );
};
