import {
    DateTimeInput,
    Form, Loading,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";

import React, { useEffect, useState } from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Color, BarElement, CategoryScale, LinearScale} from 'chart.js';
import {Chart, Pie, Bar} from 'react-chartjs-2';
import {Box, Stack, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import {Label} from "recharts";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

// Función para generar colores automáticamente
const generateColor = () => {
    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.7)`;
};

let labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'];
let dataSet = [12, 19, 3, 5, 2, 3];
export const data = {
    labels: labels,
    datasets: [
        {
            label: '# of Votes',
            data: dataSet,
            borderWidth: 1,
        },
    ]
};

// Generar colores automáticamente para cada elemento
data.datasets[0].backgroundColor = data.labels.map(() => generateColor());

const options = {
    maintainAspectRatio: false, // Desactiva el mantenimiento del aspecto para permitir cambios de tamaño
    title: {
        display: true,
        text: 'Ejemplo de Gráfico de Pie',
        fontSize: 24,
    },
    legend: {
        display: true,
        position: 'bottom',
    },
};

function getDataGrap() {
    DataGraph();
}

const optionsBar = {
    indexAxis: 'y', // Indica que el eje x será el eje vertical
    maintainAspectRatio: false, // Desactiva el mantenimiento del aspecto para permitir cambios de tamaño
    scales: {
        x: {
            beginAtZero: true,
        },
        y: {
            beginAtZero: true,
        },
    },
};

// Función para formatear la fecha en "Y-m-d"
function formatDate(date, init = false) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Ajusta el mes a dos dígitos
    let day = String(date.getDate()).padStart(2, '0'); // Ajusta el día a dos dígitos
    let hour = String(date.getHours()).padStart(2, '0'); // Ajusta la hora a dos dígitos
    let minute = String(date.getMinutes()).padStart(2, '0'); // Ajusta los minutos a dos dígitos

    if (init) {
        hour = '00:00';
    }

    return `${year}-${month}-${day} ${hour}:${minute}`;
}

let date = new Date();
date.setDate(date.getDate());
let dateStart = formatDate(date, true);
let dateEnd = formatDate(date);

export const DataGraph = () => {

    const refresh = useRefresh();
    const notify = useNotify();
    const dataContext = useRecordContext();

    const [filter, setFilter] = useState({ propertyName: '' });
    const [filteredData, setFilteredData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {

            setLoading(true);

            // Realizar la operación que necesitas antes de mostrar los datos
            const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

            let params = `?dateStart=${dateStart}+'&dateEnd=${dateEnd}`;

            let response = await fetch(`${urlAPI}/status/totals/${params}`,
                {
                    method: "GET",
                    headers: {"Content-type": "application/json; charset=UTF-8"},
                })
                .then(response => response.json());

            if (response.error) {
                notify('Se ha generado un error al intentar obtener el dataset ', { autoHideDuration: 5000, type: "error" });
                refresh();
            }

            console.log(JSON.stringify(response));

            setFilteredData(response);

        } catch (error) {
            // Manejar errores si es necesario
            console.error('Error fetching custom data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // Llamar a la función fetchData
        fetchData();
    }, [dateStart, dateEnd]);// Vuelve a cargar los datos si cambia la versión (por ejemplo, si se realiza una actualización)


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    };

    const handleSubmit = (event) => {
        //event.preventDefault();
        dateStart = filter.dateStart;
        dateEnd = filter.dateEnd;
        console.log(dateStart);
        fetchData();
    };


    if (!filteredData || loading) {
        return (
            <><Loading /></>
        );
    } else {

        const dataSTR = {
            labels: ["Activos","Eliminados"],
            datasets: [
                {
                    label: '# of Votes',
                    data: [filteredData.data.total, filteredData.data.totalDeleted],
                    borderWidth: 1,
                    backgroundColor: [
                        //'rgba(54, 162, 235, 0.7)',
                        'rgba(75, 192, 192, 0.7)',
                        'rgba(255, 99, 132, 0.7)',
                    ],
                },
            ]
        };


        let labelByClient = [];
        let dataSetByClient = [];

        filteredData.data.totalsByClients.forEach((item, index) => {
            labelByClient.push(item.nombre);
            dataSetByClient.push(item.count);
        });

        const dataClients = {
            labels: labelByClient,
            datasets: [
                {
                    label: '# of Votes',
                    data: dataSetByClient,
                    borderWidth: 1
                },
            ]
        };

        dataClients.datasets[0].backgroundColor = dataClients.labels.map(() => generateColor());

        return (
            <>
            <div>

                <Form onSubmit={handleSubmit}>
                    <Box display="flex" alignItems="center">
                        <DateTimeInput label="Fecha de inicio" defaultValue={dateStart} name="dateStart" source="dateStart" value={filter.propertyName} onChange={handleInputChange} />

                        <Box ml={1} />
                        <DateTimeInput label="Fecha final" defaultValue={dateEnd} name="dateEnd" source="dateEnd" value={filter.propertyName} onChange={handleInputChange} />

                        <Box ml={1} />
                        <Button type="submit" variant="contained" color="primary">Filtrar</Button>
                    </Box>
                </Form>

                <Box width="400px" height="300px">
                    <Label>Leads por Status</Label>
                    <Pie data={dataSTR} options={options} />
                </Box>

                <Box width="auto" height="600px">
                    <Bar data={dataClients} options={optionsBar} />
                </Box>

                {filteredData.data ? filteredData.data.totalsByClients.map((item, index) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <div>{item.idClientes}</div>
                            <div>{item.nombre}</div>
                            <div>{item.count}</div>
                        </Stack>
                    )
                }) : null}
            </div>
            </>
        );
    }

}

//Wait DataGraph load complete fetch data

const postFilters = [
    <DateTimeInput label="Fecha de Inicio" source="dateStart" name="dateStart" />,
    <DateTimeInput label="Fecha de Fin" source="dateEnd"  name="dateEnd"/>
];


export const DataStatusList = () => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <>
        <div>
            <Box>
                Datos Status Leads
                Selecciona las fechas para filtrar los datos
            </Box>

            <DataGraph />

        </div>
        </>
    );
}