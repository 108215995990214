// in src/estados.js
import React from "react";
import { useMediaQuery } from '@mui/material';
import {
    ChipField,
    Datagrid,
    List,
    ReferenceArrayField,
    SimpleList,
    SingleFieldList,
    TextField,
    Filter,
    TextInput,
    NumberField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    ShowButton,
    Create,
    SimpleForm,
    FormDataConsumer,
    AutocompleteArrayInput,
    Edit,
    EditButton,
    SelectArrayInput,
    ReferenceArrayInput,
    ArrayInputContext
} from "react-admin";

const EstadosFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn />
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1} sort={{field:'nombre',order:'ASC'}}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const roles = [
    { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
    { id: 'ROLE_LEAD_MANAGER', name: 'ROLE_LEAD_MANAGER' },
    { id: 'ROLE_GERENTE', name: 'ROLE_GERENTE' },
    { id: 'ROLE_JV_PLUS', name: 'ROLE_JV_PLUS' },
    { id: 'ROLE_JEFE_VENTAS', name: 'ROLE_JEFE_VENTAS' },
    { id: 'ROLE_CONTACT_CENTER', name: 'ROLE_CONTACT_CENTER' },
    { id: 'ROLE_CONTROL_CALIDAD', name: 'ROLE_CONTROL_CALIDAD' },
    { id: 'ROLE_COMERCIAL', name: 'ROLE_COMERCIAL'}
];

export const EstadosShow = () => (
    <Show>
        <SimpleShowLayout>
            <NumberField source="id" />
            <TextField source="nombre"/>
            <TextField label="Categoría" source="categoria.nombre"/>
            <ReferenceArrayField label='Clientes' reference='clientes' source='clientes' link='show'>
                <SingleFieldList>
                    <ChipField source='nombre' />
                </SingleFieldList>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export const EstadosList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<EstadosFilter />}>
            { isSmall ? (
                <SimpleList 
                primaryText={record => record.nombre}
                secondaryText={record => record.id}
                />
            ) : (
                <Datagrid>
                    <NumberField source="id"/>
                    <TextField source="nombre"/>
                    <TextField label="Categoría" source="categoria.nombre"/>
                    <ReferenceArrayField label='Clientes' reference='clientes' source='clientes'>
                        <SingleFieldList linkType="show">
                            <ChipField source='nombre' />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const EstadosCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="categoria" reference="categoriasEstados" perPage={-1}>
                <SelectInput name="category" optionText="nombre" resettable={true} label="Categorias" />
            </ReferenceInput>
            <ReferenceArrayInput reference="clientes" source="clientes">
                <AutocompleteArrayInput name="licenses" optionText="nombre" />
            </ReferenceArrayInput>

            <SelectArrayInput label="Roles" name="roles" choices={roles} />

        </SimpleForm>
    </Create>
);


export const EstadosEdit = props => (
    <Edit {...props}>
        <SimpleForm>

            <TextInput name="name" source="nombre" />

            <ReferenceInput source="categoria.id" reference="categoriasEstados" perPage={-1}>
                <SelectInput name="category" optionText="nombre" resettable={true} label="Categorias" />
            </ReferenceInput>

            <ReferenceArrayField label='Clientes' reference='clientes' source='clientes' link='show'>
                <SingleFieldList>
                    <ChipField source='nombre' />
                </SingleFieldList>
            </ReferenceArrayField>

        </SimpleForm>
    </Edit>
);