// in src/fieldLogs.js
import React from 'react';
import { Fragment } from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton
 } from 'react-admin';
//  import Button from '@mui/material/Button';
 import CustomResetViewsButton from './CustomResetViewsButton';
 import { useMediaQuery } from '@mui/material';
 
 const FieldsLogFilter = (props) => (
   <Filter {...props} perPage={-1}>
      <TextInput label="Search" source="q" alwaysOn/>
    </Filter>
);


const PostBulkActionButtons = props => (
  <Fragment>
      <CustomResetViewsButton label="Reset Views" {...props} />
        {/* default bulk delete action */}
      <BulkDeleteButton {...props} />
    </Fragment>
);

export const FieldsLogList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List title="Lista de Keys" filters={<FieldsLogFilter/>} bulkActionButtons={<PostBulkActionButtons />}>
      {isSmall ? (
        <SimpleList
        primaryText={record => record.key}
        secondaryText={record => record.value}
        />
        ) : (
          
            <Datagrid>
              <TextField source="id"/>
              <TextField source="key" label="Dato"/>
              <TextField source="value" label="Valor"/>
            </Datagrid>
          
              )}
    </List>
);
        };
