// in src/logsAsig.js
import React from "react";
import { useMediaQuery } from '@mui/material';
import {
    Datagrid,
    List,
    SimpleList,
    TextField,
    Filter,
    TextInput,
    NumberField
} from "react-admin";

import { JsonField, JsonInput } from "react-admin-json-view";

const LogsAsigFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn />
    </Filter>
);

export const LogsAsigList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<LogsAsigFilter />} sort={{field:'id',order:'DESC'}}>
            { isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                secondaryText={record => record.id}
                />
            ) : (
                <Datagrid>
                    <NumberField label="ID" source="id"/>
                    <TextField label="Created At" source="createdAt"/>
                    <JsonField label="Data" source="data" jsonString={false}
                               reactJsonOptions={{
                                   name: "AsigData",
                                   collapsed: true,
                                   enableClipboard: true,
                               }}
                    ></JsonField>
                </Datagrid>
            )}
        </List>
    );
}
