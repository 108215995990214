// in src/tipos.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SimpleForm,
    TextInput,
    SelectInput,
    Create,
    Filter,
    Edit,
    EditButton,
    useRecordContext,
    FormDataConsumer,
    AutocompleteArrayInput
} from 'react-admin';

import { useMediaQuery } from '@mui/material';

import Icon from '@mui/icons-material/Person';

export const VisitorIcon = Icon

 const FieldIncluseFilter = (props) => (
     <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const FieldIncluseTitle = ({ record }) => {
    return <span>Campos incluidos</span>;
};

export const FieldIncluseList = () => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title="Lista de Campos incluidos en More Info" filters={<FieldIncluseFilter />} sort={{field:'id',order:'DESC'}} >
        {isSmall ? (
            <SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.key}
            tertiaryText={record => record.group}
            />
            ) : (
            
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" label="Dato en bruto" />
                    <TextField source="key" label="Dato a mostrar" />
                    <TextField source="legend" label="Leyenda" />
                    <TextField source="group" label="Grupo" />
                    <TextField source="render" label="Render" />
                    <EditButton />
                </Datagrid>
          
        )}
    </List>
);
            };

export const FieldIncluseCreate = props => {

    //const notify = useNotify();
    const choiceCustom = [];
    let keysCustom = [];

    const getLogKeys = async () => {

        const logKeys = await GetDataLogKeys();

        logKeys.forEach(element => {
            choiceCustom.push({"key": element.key})
        });

        if (document.getElementById("spinner")) {
            document.getElementById("spinner").style = "display:none"
        }

    }

    const ChoicesName = () => {

        getLogKeys();

    }

    return (
        <>
            <Create title={<FieldIncluseTitle/>} {...props}>

                <SimpleForm>

                    <span className="spin-circle"></span>

                    <TextInput source="key" label="Dato a mostrar"/>

                    <TextInput source="legend" label="Leyenda" fullWidth multiline/>

                    <ChoicesName/>

                    <div id="spinner">
                        Cargando listado de campos ...
                    </div>

                    <FormDataConsumer>
                        {({formData, ...rest}) => (
                            <AutocompleteArrayInput label="Campos incluidos" source="name_fields" choices={choiceCustom}
                                                    optionText="key" optionValue="key" {...rest}/>
                        )}
                    </FormDataConsumer>

                    <SelectInput source="group" label="Grupo" choices={[
                        {id: 'lead', name: 'Lead'},
                        {id: 'client', name: 'Cliente'},
                        {id: 'vehicle', name: 'Vehículo'},
                    ]}/>

                    <SelectInput source="render" label="Render" choices={[
                        {id: 'text', name: 'Texto'},
                        {id: 'textArea', name: 'Comentario'},
                        {id: 'campaign', name: 'Campaña'},
                        {id: 'emailView', name: 'Visor Email'},
                        {id: 'email', name: 'Email'},
                        {id: 'phone', name: 'Teléfono'},
                        {id: 'url', name: 'Url'},
                    ]}/>

                </SimpleForm>

            </Create>
        </>
    );
};

export const FieldIncluseEdit = props => {

    //const notify = useNotify();
    const choiceCustom = [];
    let keysCustom = [];

    const getLogKeys = async () => {

        const logKeys = await GetDataLogKeys();

        logKeys.forEach(element => {
            choiceCustom.push({"key": element.key})
        });

        if (document.getElementById("spinner")) {
            document.getElementById("spinner").style = "display:none"
        }

    }

    const ChoicesName = () => {

        const record = useRecordContext();

        let names = record.name;

        names.forEach(
            element => choiceCustom.push({"key": element})
        );

        let counter = 1;
        let lengthCounter = names.length;

        names.forEach(
            element => {

                if (counter < lengthCounter) {
                    keysCustom+= element+",";
                } else {
                    keysCustom+=element;
                }

                counter++
            }
        );

        if (keysCustom.length > 0) {
            keysCustom = keysCustom.split(',');
        }

        getLogKeys();

    }

    return (
        <>
            <Edit title={<FieldIncluseTitle />} {...props}>

                <SimpleForm>

                    <span className="spin-circle"></span>

                    <TextInput source="key" label="Dato a mostrar"/>

                    <TextInput source="legend" label="Leyenda" fullWidth multiline />

                    <ChoicesName />

                    <div id="spinner">
                        Cargando listado de campos ...
                    </div>

                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <AutocompleteArrayInput label="Campos incluidos" source="name_fields" choices={choiceCustom} optionText="key" optionValue="key" defaultValue={keysCustom} {...rest}/>
                        )}
                    </FormDataConsumer>

                    <SelectInput source="group" label="Grupo" choices={[
                        {id: 'lead', name: 'Lead'},
                        {id: 'client', name: 'Cliente'},
                        {id: 'vehicle', name: 'Vehículo'},
                    ]}/>

                    <SelectInput source="render" label="Render" choices={[
                        {id: 'text', name: 'Texto'},
                        {id: 'textArea', name: 'Comentario'},
                        {id: 'campaign', name: 'Campaña'},
                        {id: 'emailView', name: 'Visor Email'},
                        {id: 'email', name: 'Email'},
                        {id: 'phone', name: 'Teléfono'},
                        {id: 'url', name: 'Url'},
                    ]}/>

                </SimpleForm>

            </Edit>
        </>
    );

}

export function GetDataLogKeys() {

    const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

    const params = "_end=-1&_order=DESC&_sort=id&_start=0";

    return fetch(`${urlAPI}/logkeys?${params}`, { method: 'GET'  })
        .then(response => response.json());

}