import {BooleanInput} from "react-admin";
import React from "react";

export const ActionPetitionEnableBilling = () => {

    return (
        <>
            <BooleanInput label="Activo" name="enabled" source="enabled" />
        </>
    );

}

export function ActionConfirmEnableBilling(data) {

    const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

    const updatedRecord = { enabled: data.enabled };

    return fetch(`${urlAPI}/billing/${data.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord) })
        .then(response => response.json());

}