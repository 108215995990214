// in src/tipos.js
import React from 'react';
// import { cloneElement } from 'react';
import {
    List,
    // Button,
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    Filter,
    CloneButton,
    BooleanInput, SaveButton, useRecordContext, useNotify,
    // Toolbar
} from 'react-admin';
//  import IconEvent from '@mui/icons-material//Event';
 import { useMediaQuery } from '@mui/material';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import ContentCopy from "@mui/icons-material/ContentCopy";

 /*const ButtonReplica = (props) => (
     <Button
     onClick={() => { alert('Your custom action'); }}
     label="Replicar Tipos"
     >
     <IconEvent/>
     </Button>
     );*/
     
     const TiposFilter = (props) => (
         <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
        <ReferenceInput label="CategoriasTipos" source="id_category_leads_tipos" reference="categoriasTipos" allowEmpty perPage={-1}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

export const CopyData = (action, props) => {

    action = Object.values(action);
    const record = useRecordContext();
    const notify = useNotify();
    let data = "";
    let message = "";

    if (action == 'nombre') {
        data = record.nombre;
        message = "Zap Name";
    }

    if (action == 'nombreMail') {
        data = record.nombreMail;
        message = "Nombre";
    }

    const handleClick = async (data) => {
        notify('Se ha copiado el ' + message, { autoHideDuration: 5000, type: "success" });
    }

    if (!data) {
        return "";
    } else {
        return <CopyToClipboard text={data} >
            <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
        </CopyToClipboard>
    }


}

const TiposTitle = ({ record }) => {
    return <span>Tipo {record ? `"${record.title}"` : ''}</span>;
};

export const TiposList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List title="Lista de Tipos de leads" filters={<TiposFilter/>} >
        {isSmall ? (
            <SimpleList
            primaryText={record => record.nombre_mail}
            secondaryText={record => record.categoriasTipos}
            tertiaryText={record => record.clientes}
            />
            ) : (

            <Datagrid>
                <TextField source="id"/>
                <ReferenceField source="clientes_id" reference="clientes" label="Cliente" allowEmpty>
                    <TextField source="nombre"/>
                </ReferenceField>

                <div label="zap_name">
                    <TextField source="nombre" label="Zap Name"/>
                    <CopyData action="nombre"></CopyData>
                </div>

                <div label="Nombre">
                    <TextField source="nombreMail" label="Nombre"/>
                    <CopyData action="nombreMail"></CopyData>
                </div>

                <ReferenceField source="id_category_leads_tipos" reference="categoriasTipos" label="Categoria"
                                filter={{is_parent: 0}} allowEmpty>
                    <TextField source="nombre"/>
                </ReferenceField>
                <EditButton/>
                <CloneButton/>
            </Datagrid>

        )}
    </List>
    );
};

export const TiposEdit = props => (
    <Edit title={<TiposTitle/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="clientes_id" reference="clientes" label="Cliente" perPage={-1}>
                           <SelectInput optionText="nombre" />
            </ReferenceInput>
            <TextInput source="nombre" label="Zap Name" />
            <TextInput source="nombre_mail" label="Nombre" />
            <ReferenceInput source="id_category_leads_tipos" reference="categoriasTipos" label="Categoria" perPage={-1} filter={{ is_parent: 0 }}>
                           <SelectInput optionText="nombre" />
            </ReferenceInput>
           <BooleanInput label="Manual" source="manual" />
           <BooleanInput label="Zapier" source="zapier" />
           <BooleanInput label="Ventas" source="ventas" />
            <SaveButton></SaveButton>

        </SimpleForm>
    </Edit>
);

export const TiposCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput source="clientes_id" reference="clientes" label="Cliente" perPage={-1}>
                           <SelectInput optionText="nombre" />
            </ReferenceInput>
            <TextInput source="nombre" label="Zap Name" />
            <TextInput source="nombre_mail" label="Nombre" />
            <ReferenceInput source="id_category_leads_tipos" reference="categoriasTipos" label="Categoria" perPage={-1} >
                           <SelectInput optionText="nombre" />
            </ReferenceInput>
           <BooleanInput label="Manual" source="manual" />
           <BooleanInput label="Zapier" source="zapier" />
           <BooleanInput label="Ventas" source="ventas" />
        </SimpleForm>
    </Create>
);