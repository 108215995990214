import {
    BooleanField,
    SaveButton,
    SimpleForm,
    useNotify, useRecordContext,
    useRefresh,
} from "react-admin";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toolbar from "@mui/material/Toolbar";
import {ActionPetitionEnableBilling, ActionConfirmEnableBilling} from "./EnableDisableBilling";

export const BillingEditDialog = (action, props) => {

    action = Object.values(action);

    const [open, setOpen] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async (data) => {

        if (action == 'enableBilling') {
            await ActionConfirmEnableBilling(data);
        }

        console.log('>>>>>>>>>>>>>>>>');

        refresh();

        setOpen(false);

    };

    let buttonText = '';
    let formPetition = '';
    if (action == 'enableBilling') {
        buttonText = <BooleanField source="enabled" />;
        formPetition = <ActionPetitionEnableBilling />;
    }

    return (
        <>

            <Button label="Deshabilitar facturación" onClick={handleClick} >{buttonText}</Button>

            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Seguro desea realizar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <SimpleForm
                            form="post-quick-create"
                            resource="put"
                            onSubmit={handleConfirm}
                            toolbar={null}
                            component="p"
                        >

                            {formPetition}

                            <Toolbar>
                                <Button onClick={handleDialogClose}>Cancelar</Button>
                                <SaveButton label="Actualizar" />
                            </Toolbar>

                        </SimpleForm>

                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </>
    );
};
