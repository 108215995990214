import {Box, Card, Divider} from "@mui/material";
import {
    BooleanInput,
    Form,
    Labeled,
    NumberInput, SaveButton, Tab, TabbedShowLayout, TabbedShowLayoutTabs,
    TextInput,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {JsonInput} from "react-admin-json-view";
import CardContent from "@mui/material/CardContent";
import React from "react";

let type = 'services';

const Separator = () => <Box pt="0.3em" />;

export const TaskForm = ({ service }) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const dataContext = useRecordContext();

    let sourceId = '';

    let servicesData = null;

    dataContext.services.forEach(element => {
        if (element.name === service) {
            servicesData = element;
        }
    });

    if (!servicesData) {
        return "Services not defined";
    }

    sourceId = servicesData.id;

    const onSubmit = async (data) => {

        const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

        let dataPost = {
            'service_data': data.data,
            'service_id': data.service_id,
            'active': data.service_active,
            'type': data.service_name
        };

        let response = await fetch(`${urlAPI}/services/update/${type}/${data.id}`,
            {
                method: "POST",
                body: JSON.stringify(dataPost),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            })
            .then(response => response.json());

        if (response) {
            notify('Se ha procesado el registro del servicio ' + data.service_name, { autoHideDuration: 5000, type: "success" });
            refresh();
        }

    }

    return (

        <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left" p={5} m={5} bgcolor="background.paper">

            <h1>{service}</h1>

            {servicesData.registrered ? (
                <Labeled sx={{ color: "green" }}>
                    <h3>* Registrado</h3>
                </Labeled>
            ) : (
                <Labeled sx={{ color: "red" }}>
                    <h3>* No Registrado</h3>
                </Labeled>
            )}

            <Form onSubmit={onSubmit}>

                <NumberInput name="service_id" source="" defaultValue={sourceId} disabled={true}></NumberInput> <Separator />

                <TextInput name="service_name" source="" defaultValue={service} disabled={true}></TextInput> <Separator />

                <BooleanInput name="service_active" source="" defaultValue={servicesData.active} label="Activa/Desactiva" />

                <JsonInput name="data" source="" defaultValue={servicesData.data}
                           reactJsonOptions={{
                               // Props passed to react-json-view
                               name: null,
                               collapsed: false,
                               enableClipboard: true,
                               displayDataTypes: false,
                           }} />

                <SaveButton />

            </Form>

        </Box>
    )
}

export const LoadData = (data, props) => {

    const dataContext = useRecordContext();

    if (!dataContext) {
        return "No data";
    }

    type = data.typeData;

    return (<Card>
        <CardContent>
            <h1>Servicios</h1>
            <TabbedShowLayout divider={<Divider flexItem />} tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"  />} sx={{
                backgroundColor: "#eceff1",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "MistyRose",
                },
                width: "90%"
            }}
            >
                {dataContext.services ? dataContext.services.map((service) => (
                    <Tab label={service.name} key={service.id} sx={{ color: service.active ? "green" : "red" }}>
                        <TaskForm service={service.name} />
                    </Tab>
                )) : null}

            </TabbedShowLayout>
        </CardContent>
    </Card>);

}