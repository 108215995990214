
import {
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ShowButton,
    EditButton,
    useRecordContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    DeleteWithConfirmButton,
    ReferenceInput,
    SelectInput,
    ListBase,
    FilterForm,
    Pagination,
    useNotify,
    Create,
    TextInput,
    Edit,
    SimpleForm,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    AutocompleteInput,
    Labeled, email
} from 'react-admin';

import { useEffect } from 'react';
import {useMediaQuery, Stack} from '@mui/material';
import { StringToLabelObject } from './App';

import { useFormContext } from 'react-hook-form';

import {UserEditDialog} from "./Utils/Dialogs/UserEditDialog";
import React from "react";
import Button from "@mui/material/Button";
import ContentCopy from '@mui/icons-material/ContentCopy';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import {LoadData} from "./Utils/Services/Edit_services";

import DownloadIcon from '@mui/icons-material/Download';

const UsersTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Usuario: {record.email}</span>;
}

const  usersFilters = [
    <TextInput label="Buscar..." source="q" alwaysOn />,
    <ReferenceInput label='Cliente' source='id_clientes' reference='clientes' allowEmpty perPage={-1} sort={{field:'nombre', order:'ASC'}}>
        <SelectInput optionText="nombre" />
    </ReferenceInput>
];

const UsersListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={usersFilters} />
        <div>
            <FilterButton filters={usersFilters} />
            <CreateButton />
        </div>
        <div>
            <UserReportButton />
        </div>
    </Stack>
)

const UsersShowActions = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

export const CopyData = (action, props) => {

    action = Object.values(action);
    const record = useRecordContext();
    const notify = useNotify();
    let data = "";

    const handleClick = async (data) => {
        notify('Se ha copiado el ' + action, { autoHideDuration: 5000, type: "success" });
    }

    if (action == 'email') {
        data = record.email;
    }

    if (action == 'token') {
        data = record.token;
    }

    if (action == 'id') {
        data = record.id;
    }

    if (action == 'nombre') {
        data = record.nombre;
    }

    if (!data) {
        return "";
    } else {
        return <CopyToClipboard text={data} >
            <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
        </CopyToClipboard>
    }

}

export const UsersShow = () => (
    <Show title={<UsersTitle />} actions={<UsersShowActions />}>
        <SimpleShowLayout>
                    <TextField source="nombre" />
                    <TextField source="apellidos" />
                    <TextField source="email" />
                    <TextField source="cargo" />
                    <TextField source="telefono" />
                    <BooleanField source="enabled" />
                    <DateField source="last_login" showTime />
                    <ArrayField source="roles">
                        <SingleFieldList>
                            <StringToLabelObject>
                                <ChipField source="label" />
                            </StringToLabelObject>
                        </SingleFieldList>
                    </ArrayField>
                    
                    <TextField source="token" />
                    <BooleanField source="activo" />
                    <DateField source="fecha_alta" />
                    <DateField source="fecha_baja" />
                    <ReferenceField label="Cliente" source="cliente" reference="clientes">
                        <TextField source="nombre" />
                    </ReferenceField>
                    <TextField source="concesionario" />
        </SimpleShowLayout>
    </Show>
);

export const UsersList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
    <ListBase>
        <UsersListToolbar />
        {isSmall ? (
            <SimpleList
            primaryText={record => record.nombre}
            secondaryText={record => record.id}
            tertiaryText={record => record.activo}
            />
            ) : (
            <Datagrid>
                <div label="id">
                    <TextField source="id"/>
                    <CopyData action="id"></CopyData>
                </div>
                <TextField source="nombre"/>
                <TextField source="apellidos"/>

                <div label="Email">
                    <TextField source="email"/>
                    <CopyData action="email"></CopyData>
                </div>

                <TextField source="cargo"/>
                <TextField source="telefono"/>

                <div label="Status">
                    <UserEditDialog action="enableUser"/>
                </div>

                <DateField source="last_login" showTime/>
                <ArrayField source="roles">
                    <SingleFieldList>
                        <StringToLabelObject>
                            <ChipField source="label"/>
                        </StringToLabelObject>
                    </SingleFieldList>
                </ArrayField>

                <div label="Token">
                    <TextField source="token"/>
                    <CopyData action="token"></CopyData>
                </div>

                <BooleanField source="activo"/>
                <DateField source="fecha_alta"/>
                <DateField source="fecha_baja"/>
                <ReferenceField label="Cliente" source="cliente" reference="clientes">
                    <TextField source="nombre"/>
                </ReferenceField>
                <TextField source="concesionario"/>
                <ShowButton/>

                <UserEditDialog action="enableZapier"/>


                <UserEditDialog action="enableUserServices"/>

                <EditButton/>
                <DeleteWithConfirmButton
                    confirmContent="¿Estás seguro de eliminar este usuario?"
                />
            </Datagrid>
        )}
        <Pagination/>
    </ListBase>
    );

};

const roles = [
    {id: 'ROLE_USER', name: 'ROLE_USER'},
    {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
    { id: 'ROLE_LEAD_MANAGER', name: 'ROLE_LEAD_MANAGER' },
    { id: 'ROLE_GERENTE', name: 'ROLE_GERENTE' },
    { id: 'ROLE_JV_PLUS', name: 'ROLE_JV_PLUS' },
    { id: 'ROLE_JEFE_VENTAS', name: 'ROLE_JEFE_VENTAS' },
    { id: 'ROLE_CONTACT_CENTER', name: 'ROLE_CONTACT_CENTER' },
    { id: 'ROLE_CONTROL_CALIDAD', name: 'ROLE_CONTROL_CALIDAD' },
    { id: 'ROLE_COMERCIAL', name: 'ROLE_COMERCIAL'},
    { id: 'SERVICE_ZAPIER', name: 'SERVICE_ZAPIER'}
];

const updateUserPermissions = (id, cliente) => {

    const urlAPI = `${process.env.REACT_APP_API_URL}`;
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : {};
    const username = userData.username || '';

    return fetch(`${urlAPI}lt/configUserPermissions?idUser=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
    })
        .then(response => response.json())
        .then(data => {
            window.location.reload();
        });

};

const UpdateUserPermissionsButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Button
            label='Actualizar permisos por defecto'
            onClick={() => updateUserPermissions(record.id, record.cliente)}
        >
            Actualizar permisos por defecto
        </Button>
    );
}

export const UsersEdit = props => {

    const isDisabled = true;
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : {};

    // const record = useRecordContext();

    return (
        <Edit {...props}>
            <SimpleForm>

                <TextInput type="hidden" source="storedUsername" defaultValue={userData.username} />

                <TextInput label="ID" source="id" disabled />

                <ReferenceInput label="Cliente" source="cliente" reference="clientes" allowEmpty perPage={-1}>
                    <AutocompleteInput optionText="nombre" sx={{ width: '300px' }} />
                </ReferenceInput>

                <TextInput label="Nombre" source="nombre" />
                <TextInput label="Apellidos" source="apellidos" />
                <TextInput label="Email" source="email" type="email" />

                <SelectArrayInput source="roles" label="Roles" choices={roles} />

                <div style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto' }}>
                    <Labeled label="Servicios de usuarios">
                        <ArrayInput label="" name="services" source="userServices">
                            <SimpleFormIterator
                                inline
                                disableAdd={true}
                                disableRemove={true}
                                disableReordering={true}
                                getItemLabel={() => ''}
                            >
                                <TextField source="title" />
                                <BooleanInput source="active" helperText={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Labeled>
                </div>

                <div style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto' }}>
                    <UpdateUserPermissionsButton />
                </div>
            </SimpleForm>

            <LoadData typeData="user" />
        </Edit>
    );
};

export const UsersCreate = props => (
    <Create {...props}>
    <SimpleForm>

                <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1}>
                    <AutocompleteInput optionText="nombre" sx={{
                        width: '300px'
                    }}></AutocompleteInput>
                </ReferenceInput>

                <TextInput label="Nombre" source="name" />
                <TextInput label="Apellidos" source="last_name" />
                <TextInput label="Email" source="email" type="email" />
                <TextInput label="Contraseña" source="password" />
                <SelectArrayInput source="roles" label="Roles" choices={roles}/>

                <div>Servicios de usuarios</div><br />
                <BooleanInput label="send_email" source="send_email"></BooleanInput>
                <BooleanInput label="delete_leads" source="delete_leads"></BooleanInput>
                <BooleanInput label="qualify_leads" source="qualify_leads"></BooleanInput>
                <BooleanInput label="comercial_asig" source="comercial_asig"></BooleanInput>
                <BooleanInput label="receive_email_not" source="receive_email_not"></BooleanInput>

            </SimpleForm>
        </Create>
);

const downloadUserReport = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}lt/users/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
            }
        });

        if (!response.ok) {
            throw new Error('Error al descargar el informe');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;
        const fileDownloadName = `USERS - INFORME USUARIOS POR CLIENTE ${formattedDate}.xlsx`;
        link.setAttribute('download', fileDownloadName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error:', error);
    }
};

const UserReportButton = () => (
    <Button
        startIcon={<DownloadIcon />}
        onClick={downloadUserReport}
    >
        Informe de usuarios
    </Button>
);
