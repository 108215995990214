// in src/inboxparser.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    Filter,
    CloneButton,
    FormDataConsumer,
    useRecordContext, useNotify
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import ContentCopy from "@mui/icons-material/ContentCopy";

const InboxparserFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

export const CopyData = (action, props) => {

    action = Object.values(action);
    const record = useRecordContext();
    const notify = useNotify();
    let data = "";
    let message = "";

    if (action == 'nombre') {
        data = record.nombre;
        message = "Nombre";
    }

    if (action == 'id') {
        data = record.id;
        message = "ID";
    }

    const handleClick = async (data) => {
        notify('Se ha copiado el ' + message, { autoHideDuration: 5000, type: "success" });
    }

    if (!data) {
        return "";
    } else {
        return <CopyToClipboard text={data} >
            <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
        </CopyToClipboard>
    }


}

const InboxparserTitle = ({ record }) => {
    return <span>Website {record ? `"${record.title}"` : ''}</span>;
};

export const InboxparserList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<InboxparserFilter />} >
        {isSmall ? (
            <SimpleList
            primaryText={record => record.nombre}
            secondaryText={record => record.web}
            tertiaryText={record => record.id_sedes}
            />
            ) : (

            <Datagrid>

                <div label="ID">
                    <TextField source="id"/>
                    <CopyData action="id"></CopyData>
                </div>

                <div label="Nombre">
                    <TextField source="nombre"/>
                    <CopyData action="nombre"></CopyData>
                </div>

                <ReferenceField source="id_clientes" reference="clientes" label="Cliente">
                    <TextField source="nombre"/>
                </ReferenceField>
                <ReferenceField source="concesionarios_lc" reference="concesionarios" label="Concesionario">
                    <TextField source="nombre"/>
                </ReferenceField>
                <ReferenceField source="sedes_lc" reference="sedes" label="Sede">
                    <TextField source="nombre"/>
                </ReferenceField>
                {/* <TextField source="id_sedes" />
                    <MyUrlField source="web" /> */}
                <EditButton/>
                <CloneButton/>
            </Datagrid>
        )}
    </List>
    );
};

export const InboxparserEdit = props => (
    <Edit title={<InboxparserTitle/>} {...props}>
        <SimpleForm>
        <TextInput disabled source="id" />
            <TextInput source="nombre" />
            <ReferenceInput source="id_clientes" reference="clientes" perPage={-1} filter={{ activo: true }}>
                <SelectInput optionText="nombre" resettable={true} label="Cliente" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="concesionarios_lc" reference="concesionarios" perPage={-1} filter={{ cliente: formData.id_clientes, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Concesionario" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="sedes_lc" reference="sedes" perPage={-1} filter={{ concesionario: formData.concesionarios_lc, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Sede" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
           {/* <TextInput source="id_sedes" /> */}
           {/* <TextInput source="web" /> */}
        </SimpleForm>
    </Edit>
);

export const InboxparserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <ReferenceInput source="id_clientes" reference="clientes" perPage={-1} filter={{ activo: true }}>
                <SelectInput optionText="nombre" resettable={true} label="Cliente" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="concesionarios_lc" reference="concesionarios" perPage={-1} filter={{ cliente: formData.id_clientes, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Concesionario" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="sedes_lc" reference="sedes" perPage={-1} filter={{ concesionario: formData.concesionarios_lc, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Sede" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);