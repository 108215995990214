import {BooleanInput, TextInput} from "react-admin";
import React from "react";

export const ActionPetitionEnableZapier = () => {

    return (
        <>
            Activar / Desactivar Zapier
            <TextInput name="email" source="email" disabled></TextInput>
            <BooleanInput label="Zapier" source="zapier" />
        </>
    );

}

export function ActionConfirmEnableZapier(data) {

    const urlAPI = `${process.env.REACT_APP_API_URL}`;

    const updatedRecord = { enabled: data.zapier };

    return fetch(`${urlAPI}lt/users/zapier/${data.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord) })
        .then(response => response.json());

}